// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-mdx-layout-js": () => import("./../../../src/components/MdxLayout.js" /* webpackChunkName: "component---src-components-mdx-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-templates-topic-template-js": () => import("./../../../src/templates/topicTemplate.js" /* webpackChunkName: "component---src-templates-topic-template-js" */)
}

